<template>
    <v-app>
        <v-main>
            <v-row>
                <v-col cols="8" class="mx-auto">
                    
                        <v-form @submit.prevent="not_search_data_siett($store.state.SIETT.form.placa)" v-model="valid">
                            <v-text-field
                                v-model="$store.state.SIETT.form.placa"
                                
                                :rules="[
                                        v => !!v || 'El campo es obligatorio']"
                                :append-outer-icon="'mdi-magnify'"
                                @click:append-outer="not_search_data_siett($store.state.SIETT.form.placa)"
                                label="Placa"
                                required
                                filled rounded
                                dense
                                
                            ></v-text-field>
                        </v-form>
                </v-col>
            </v-row>

           
            <v-row v-show="valid==true && show_results==true">
                <v-col>
                    <Empalme :tramites.sync="tramites" ></Empalme>
                </v-col>
                
            </v-row>
            <v-dialog v-model="dialogo_alerta_placa_digitalizada" max-width="500">
                <Card :titulo="'ALERTA: Placa encontrada en la plataforma'" :color="'orange'" @activar="dialogo_alerta_placa_digitalizada=false">
                    <template v-slot:contenido>
                        <br>
                         <h4> La placa tiene los tramites de: {{tramites_en_placa}} </h4>
                        <br>
                    </template>
                </Card>
            </v-dialog>
        </v-main>
    </v-app>
</template>
<script>
import Card from '../components/celulas/Card.vue';
import Empalme from '../components/SIETT/Empalme.vue';
export default {
    components: {
    Card,
    Empalme
},
    data(){
        return {
            tramites: [],
            valid: true,
            show_results: false,
            tramites_en_placa: "",
            dialogo_alerta_placa_digitalizada: false
            
        }
    },
    created(){
        console.log('xd');
        this.tramites.push({
            Placa: "",
            Tipo_de_tramite: "",
            id_Tipo_de_tramite: "",
            Fecha: "",
            Documento_del_propietario: "",
            fecha_documento_int: 0,
            fecha_creacion_int: Date.parse(new Date()),
            order: Date.parse(new Date()),
            estatus: "HABILITADA",
            extended_properties: "{}",
            imagenes: [],
            datos_archivos: [],
            files: [],
            status: "",
            Observaciones: "",
            autor: this.$store.state.dataUser.dataUser.nomre,
            Parque_automotor: "PARTICULAR",
            Tipo_de_vehiculo: "",
            Rango_placa: "",
            Numero_rango_placa: "",
        })
    },
    watch: {
        valid(val){
            if(!val){
                this.show_results = false;
            }
        }
    },
    methods: {
        async not_search_data_siett(placa){
            this.show_results = false;
            this.tramites = [];
            this.tramites.push({
                Placa: "",
                Tipo_de_tramite: "",
                id_Tipo_de_tramite: "",
                Fecha: "",
                Documento_del_propietario: "",
                fecha_documento_int: 0,
                fecha_creacion_int: Date.parse(new Date()),
                order: Date.parse(new Date()),
                estatus: "HABILITADA",
                extended_properties: "{}",
                imagenes: [],
                datos_archivos: [],
                files: [],
                status: "",
                Observaciones: "",
                autor: this.$store.state.dataUser.dataUser.nomre,
                Parque_automotor: "PARTICULAR",
                Tipo_de_vehiculo: "",
                Rango_placa: "",
                Numero_rango_placa: "",


            });
            this.show_results = true;

            const coleccion = "V1_SIETT_DOCUMENT_V3_3";  //Colocar nueva colección
            const busqueda = placa;
            const query_by = "Placa";
            const filter_by = `estatus:[${"HABILITADA"}]&&Placa:[${placa}]`;
            const response = await this.$store.dispatch('llamado_get',{
                url: "https://siett.educandote.co/types/collections/"+coleccion+"/documents/search?q="+busqueda+"&query_by="+query_by+"&filter_by="+filter_by+"&per_page=250&page=1",
                tipo_header: "indexador"
            })

            if(response.data.found>0){
                this.tramites_en_placa = "";
                for (let index = 0; index < response.data.hits.length; index++) {
                    this.tramites_en_placa = this.tramites_en_placa+` ${response.data.hits[index].document.Tipo_de_tramite}`
                    
                }
                
                this.dialogo_alerta_placa_digitalizada = true;
            }
            console.log(response);
        },
        async search_data_siett(placa){
            this.show_results = false;
            this.tramites = [];
            console.log('xssss')
            try {
                const response = await this.$store.dispatch('SIETT/GET_DATA_BY_PLACA',placa);
                console.log(response);

                if(response.length==0){
                    this.tramites.push({
                        Placa: "",
                        Tipo_de_tramite: [],
                        id_Tipo_de_tramite: "",
                        Fecha: "",
                        Documento_del_propietario: "",
                        fecha_documento_int: 0,
                        fecha_creacion_int: Date.parse(new Date()),
                        order: Date.parse(new Date()),
                        estatus: "HABILITADA",
                        extended_properties: "{}",
                        imagenes: [],
                        datos_archivos: [],
                        files: [],
                        status: "",
                        Observaciones: "",


                    })
                }else{
                    
                    for (let index = 0; index < response.length; index++) {
                       response[index].Tipo_de_tramite = [{
                        id: response[index].id_Tipo_de_tramite,
                        name: response[index].Tipo_de_tramite,
                       }]
                        
                    }

                    for (let index = 0; index < response.length; index++) {
                         
                        this.tramites.push({...response[index], imagenes: [], datos_archivos: [], files: [], status: "", Observaciones: "", })
                        
                    }
                }
                
                //this.tramites = [...response];
            } catch (error) {
                console.log(error);
            }

            this.show_results = true;
        }
    },
    computed: {
        
    }
}
</script>