<template>
    <v-main class="ma-5">
        <v-sheet class="mx-5">
             <Toolbar :accion="'Crear'" 
                        :icono="'mdi-file-document-multiple-outline'" 
                        :titulo="'Formato documental'" 
                        @activar="dialogo_crear_formato_documental=true"
                >

                   <template v-slot:accion_retroceder>
                       <v-btn icon @click="$router.go(-1)" > <v-icon> mdi-arrow-left </v-icon> </v-btn>
                   </template>
                   
            </Toolbar>  
            <v-card-title>
                <v-row>
                <v-col>
                    <v-img  contain="" max-width="150" :aspect-ratio="16/9" :src="require('../assets/inventario.png')">
                </v-img>
                </v-col>
                <v-col>
                    <v-text-field @click:append-outer="dialogo_selecionar_filtro=true"  :append-outer-icon="'mdi-magnify'"  label="Buscar" v-model="search"></v-text-field>

                </v-col>
                <v-col>
                    <vue-excel-xlsx
                        :data="desserts"
                        :columns="columns"
                        :filename="'FUID'"
                        :sheetname="'FUID'" 

                        
                        >
                        <v-btn class="mt-1" block rounded="" dark color="white" style="color:blue" >
                            <span>
                                Descargar
                            </span>
                            <v-icon>
                                mdi-microsoft-excel
                            </v-icon>
                        </v-btn>
                    </vue-excel-xlsx> 
                </v-col>
                </v-row>
            </v-card-title>
            <Paginacion :cantidad_paginas="cantidad_paginas" @buscar="page=$event" />
            <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            :page.sync="page"
            :items-per-page="250"
            
            >
            <template v-slot:item.actions="{ item }">
                <v-icon
                    class="mr-2"
                    color="indigo"
                        @click="dialogo_mostrar_observacion=true;info=item"
                    >
                    mdi-book-open-variant
                    </v-icon>
                </template>
            </v-data-table>
            
        </v-sheet>
        <v-dialog fullscreen persistent v-model="dialogo_crear_formato_documental" >
            <Card :load="load" @activar="dialogo_crear_formato_documental=false" :titulo="'Creacion del formato documental'">
                <template v-slot:contenido >
                    <CrearFUID @campos_fuid="enviar_datos_fuid($event)" />
                </template>
            </Card>
        </v-dialog>
        <v-dialog v-model="dialogo_selecionar_filtro" max-width="400">
          <Card @activar="dialogo_selecionar_filtro=false" :titulo="'Selecciona el filtro'" >
            <template v-slot:contenido>
                <br>
                <v-autocomplete prepend-inner-icon="mdi-magnify" filled rounded dense 
                  @change="buscar_datos_fuid(search,filtro_seleccionado.filtro)"
                  v-model="filtro_seleccionado"
                  :item-text="'nombre'"
                  :items="filtros"
                  return-object
                  label="Filtrar por..."
                  >

                </v-autocomplete>
            </template>
          </Card>
        </v-dialog>
        <v-dialog v-model="dialogo_mostrar_observacion" max-width="600">
          <Card @activar="dialogo_mostrar_observacion=false" :titulo="'Observaciones'" >
            <template v-slot:contenido>
                <v-card-text>
                    <p>
                        {{info.observaciones}}
                    </p>
                </v-card-text>
            </template>
          </Card>
        </v-dialog>
    </v-main>
</template>
<script>
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from 'vue'
Vue.use(VueExcelXlsx)
import CrearFUID from './CrearFUID.vue'
//const axios = require("axios").default;
export default {
    components: {
        CrearFUID, 
    },
    data(){
        return {
            dialogo_crear_formato_documental: false,
            desserts: [],
            dialogo_selecionar_filtro: false,
            filtro_seleccionado: {},
            search: '',
            page: 1,
            load: false,
            info: {
                observaciones: '',
            },
            cantidad_paginas: 1,
            dialogo_mostrar_observacion: false,
            nombre_endpoint_almacenamiento: 'inventario_documental02',
            //campos: null,
            filtros: [
                {
                    nombre: 'Codigo de serie documental',
                    filtro: 'serie_documental',
                },
                {
                    nombre: 'Nombre de la serie y asuntos',
                    filtro: 'nombre_serie',
                },
                {
                    nombre: 'Numero consecutivo',
                    filtro: 'numero_consecutivo',
                },
                {
                    nombre: 'Fecha inicial',
                    filtro: 'fecha_inicial',
                },
                {
                    nombre: 'Unidad de conservacion CAJA',
                    filtro: 'caja_unidad_conservacion',
                },
                {
                    nombre: 'Unidad de conservacion CARPETA',
                    filtro: 'carpeta_unidad_conservacion',
                },
                {
                    nombre: 'Unidad de conservacion TOMO',
                    filtro: 'tomo_unidad_conservacion',
                },
                {
                    nombre: 'Unidad de conservacion OTRO',
                    filtro: 'otro_unidad_conservacion',
                },
                {
                    nombre: 'Numero de folios',
                    filtro: 'numero_folios',
                },
                {
                    nombre: 'Soporte',
                    filtro: 'soporte',
                },
                {
                    nombre: 'Frecuencia de consulta',
                    filtro: 'frecuencia_consulta',
                },
                {
                    nombre: 'Signatura topografica MODULO',
                    filtro: 'signatura_topografica_modulo',
                },
                {
                    nombre: 'Signatura topografica ESTANTE',
                    filtro: 'signatura_topografica_estante',
                },
                {
                    nombre: 'Signatura topografica ENTREPAÑO',
                    filtro: 'signatura_topografica_entrepaño',
                },
                {
                    nombre: 'Signatura topografica CAJA',
                    filtro: 'signatura_topografica_caja',
                },
                {
                    nombre: 'Signatura topografica CARPETA',
                    filtro: 'signatura_topografica_carpeta',
                },
                
            ],
            
            headers: [
                { text: 'Codigo de serie documental', value: 'codigo_documental' },
                { text: 'Nombre de la serie y asuntos', value: 'nombre_serie' },
                { text: 'Numero consecutivo', value: 'numero_consecutivo' },
                { text: 'Fecha inicial', value: 'fecha_inicial' },
                { text: 'Fecha final', value: 'fecha_final' },
                { text: 'U/C Caja', value: 'caja_unidad_conservacion' },
                { text: 'U/C Carpeta', value: 'carpeta_unidad_conservacion' },
                { text: 'U/C Tomo', value: 'tomo_unidad_conservacion' },
                { text: 'U/C Otro', value: 'otro_unidad_conservacion' },
                { text: 'Numero de folios', value: 'numero_folios' },
                { text: 'Soporte', value: 'soporte' },
                { text: 'Frecuencia de consulta', value: 'frecuencia_consulta' },
                { text: 'S/TP Modulo', value: 'signatura_topografica_modulo' },
                { text: 'S/TP Estante', value: 'signatura_topografica_estante' },
                { text: 'S/TP Entrepaño', value: 'signatura_topografica_entrepaño' },
                { text: 'S/TP Caja', value: 'signatura_topografica_caja' },
                { text: 'S/TP Carpeta', value: 'signatura_topografica_carpeta' },
                { text: 'Observaciones', value: 'actions',  },
            

            ],
            columns: [
                
                
                { label: 'Codigo de serie documental', field: 'codigo_documental' },
                { label: 'Nombre de la serie y asuntos', field: 'nombre_serie' },
                { label: 'Numero consecutivo', field: 'numero_consecutivo' },
                { label: 'Fecha inicial', field: 'fecha_inicial', dataFormat: this.formato_fecha },
                { label: 'Fecha final', field: 'fecha_final', dataFormat: this.formato_fecha },
                { label: 'U/C Caja', field: 'caja_unidad_conservacion' },
                { label: 'U/C Carpeta', field: 'carpeta_unidad_conservacion' },
                { label: 'U/C Tomo', field: 'tomo_unidad_conservacion' },
                { label: 'U/C Otro', field: 'otro_unidad_conservacion' },
                { label: 'Numero de folios', field: 'numero_folios' },
                { label: 'Soporte', field: 'soporte' },

                { label: 'Frecuencia de consulta', field: 'frecuencia_consulta' },
                { label: 'S/TP Modulo', field: 'signatura_topografica_modulo' },
                { label: 'S/TP Estante', field: 'signatura_topografica_estante' },
                { label: 'S/TP Entrepaño', field: 'signatura_topografica_entrepaño' },
                { label: 'S/TP Caja', field: 'signatura_topografica_caja' },
                { label: 'S/TP Carpeta', field: 'signatura_topografica_carpeta' },
                { label: 'Observaciones', field: 'observaciones' },
                
            ],
            //@click="$store.state.meta_data_store.push({id: Date.parse(new Date())+Math.random(), name: '', type: 'string', key: '', value: '' })
        }
    },
    watch: {
        page(val){
            console.log(val)
        }
    },
    created(){
        this.buscar_datos_fuid("*","codigo_documental")
        //this.$store.dispatch('crear_endpoint_fuid')
    },
    methods: {
       async buscar_datos_fuid(buscar,filtro){
           this.desserts = []
           try {
                let response = await this.$store.dispatch('llamado_get',{
                url: "https://siett.educandote.co/types/collections/"+this.nombre_endpoint_almacenamiento+"/documents/search?q="+buscar+"&query_by="+filtro+"&per_page=250&page="+this.page
                    ,tipo_header: 'indexador'
                })
                this.cantidad_paginas = parseInt(response.data.found/250)+1
                for (let index = 0; index < response.data.hits.length; index++) {
                    this.desserts.push(response.data.hits[index].document)
                    
                }
           } catch (error) {
             console.log(error)  
           }
        },
        formato_fecha(fecha){
           console.log(fecha)
           let aux = fecha.replace(/-/g,';')
           console.log(aux)
           return aux

        },
       async enviar_datos_fuid(campos){
            console.log(campos)
           this.load = true
           
            try {
               
                
                let response = await  this.$store.dispatch('llamado_post',{
                    url:"https://siett.educandote.co/types/collections/"+this.nombre_endpoint_almacenamiento+"/documents",
                    tipo_header: "indexador",
                    body: campos
                })
                console.log(response)
                this.desserts.push({
                    objectId: response.data.objectId,
                    ...campos
                })
                this.dialogo_crear_formato_documental = false
               
            } catch (error) {
                console.log(error)
                this.$store.commit(
                "error",
                "Algo ha salido mal almacenando el documento, por favor intente mas tarde, si el error persiste informe a soporte"+error.message
                );
            }
            this.load = false
           

        },
        activar(){
            console.log('Acstivssaso')
        }
    }
}
</script>